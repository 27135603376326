.l-default {
	display: grid;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-default {
		grid-column-gap: var(--column-gap);
		grid-template-columns: auto 39.25rem 16.75rem auto;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.l-default {
		grid-template-columns: auto 48.625rem 18.625rem auto;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-default {
		grid-template-columns: auto 59.875rem 18.625rem auto;
	}
}

/* #region Intro */
@media (width >= 64rem) {
	.l-default__intro {
		box-sizing: border-box;
		grid-column: span 4;
		margin: 0 auto;
		width: 100%;
		max-width: 58rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.l-default__intro {
		max-width: 69.25rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-default__intro {
		max-width: 80.5rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-default[data-has-sub-nav] .l-default__intro {
		box-sizing: border-box;
		grid-column: 2;
		padding-inline-end: 0;
	}
}

/* #endregion Intro */

/* #region Subnav */
.l-default__sub-navigation {
	padding-block-end: var(--space-40--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-default__sub-navigation {
		padding-block-end: var(--space-56--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-default__sub-navigation {
		padding-block-end: var(--space-80--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-default__sub-navigation {
		grid-row: 1;
		grid-column: 3;
		margin-block-end: 0;
		padding-block-end: 0;
	}
}

/* #endregion Subnav */

/* #region Intro + Subnav */
/* up-to-xsmall,max-width-567px */
@media (width < 35.5rem) {
	.l-default__intro,
	.l-default__sub-navigation {
		padding-inline: var(--space-20--px);
	}
}

/* from x-small min-width-568p, up-to-small,max-width-767px */
@media (min-width: 35.5rem) and (width < 48rem) {
	.l-default__intro,
	.l-default__sub-navigation {
		padding-inline: var(--space-30--px);
	}
}

/* from-small,tablet-p,min-width-768px, up-to-medium,max-width-1023px */
@media (width >= 48rem) and (width < 64rem) {
	.l-default__intro,
	.l-default__sub-navigation {
		margin: 0 auto;
		padding-inline: 0;
		width: 100%;
		max-width: 43.5rem;
	}
}
/* #endregion Intro + Subnav */

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-default__main {
		grid-column: span 4;
	}
}
