.filter {
}

.filter__block:not(:last-child) {
	margin-block-end: var(--space-24--rem);
}

.filter__block__title {
	color: var(--color-brown);
	margin-block-start: 0;
	margin-block-end: var(--space-16--rem);
}

.filter__block__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.filter__block__list__item:not(:last-child) {
	margin-block-end: var(--space-2--rem);
}

.filter__block__list__item__link {
	color: var(--color-brown);
	display: inline-block;
	text-decoration-color: transparent;
	transition:
		text-decoration-color 176ms ease-in-out,
		color 176ms ease-in-out;
}

/* up-to-wide,max-width-1279px */
@media (width < 80rem) {
	.filter__block__list__item__link {
		margin-block: 0.1875rem;
		padding-block: 1px;
	}
}

.filter__block__list__item__link:is(:hover, :active),
.filter__block__list__item[data-is-current] .filter__block__list__item__link {
	color: var(--color-black);
	text-decoration-color: var(--color-black);
}
