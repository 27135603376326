.regions-map {
}

.regions-map__iframe-container {
	aspect-ratio: 4 / 5;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.regions-map__iframe-container {
		aspect-ratio: 16 / 9;
	}
}

.regions-map__iframe {
	border-radius: var(--border-radius-10);
	display: block;
	margin-block: var(--space-32--rem);
	width: 100%;
	height: 100%;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.regions-map__iframe {
		margin-block: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.regions-map__iframe {
		margin-block: var(--space-56--rem);
	}
}
