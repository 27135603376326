:root {
	--theme-logo: url("../images/logo-default.svg");
	--theme-gradient: var(--gradient-yellow-orange);
	--theme-navigation-overlay-secondary-text-color: var(--color-black);
}

[data-theme-page="expertise"] {
	--theme-logo: url("../images/logo-expertise.svg");
	--theme-gradient: var(--gradient-red-brown);
	--theme-navigation-overlay-secondary-text-color: var(--color-white);
}
