.teaser-b {
	border: 0.125rem solid var(--color-orange);
	border-radius: var(--border-radius-10);
}

.teaser-b__link {
	box-sizing: border-box;
	display: block;
	padding-inline: var(--space-24--px);
	padding-block: var(--space-24--rem);
	text-decoration: none;
	height: 100%;
}

.teaser-b__title {
	color: var(--color-brown);
	margin-block-start: 0;
	text-decoration: underline;
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.teaser-b:hover .teaser-b__title {
	text-decoration-color: var(--color-orange);
}

.teaser-b__excerpt {
	margin-block-end: 0;
}
