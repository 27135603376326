.cnw_facetedsearch .cnw_facet-search {
	/* type-h */
	font-family: var(--font-body-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
}

.cnw_w .cnw_facetedsearch .cnw_block-title {
	margin-block-end: var(--space-16--rem);
}

.cnw_facetedsearch .cnw_facet-search .cnw_facet-label:not(:last-child) {
	margin-block-end: var(--space-2--rem);
}

.cnw_facetedsearch .cnw_facet-search .cnw_facet-label:not(.active) a {
	color: var(--color-blue-dark);
	text-decoration-color: transparent;
	transition:
		text-decoration-color 176ms ease-in-out,
		color 176ms ease-in-out;
}

.cnw_facetedsearch .cnw_facet-search .cnw_facet-label:not(.active) a:hover {
	color: var(--color-black);
	text-decoration-color: var(--color-black);
}

.cnw_facetedsearch .cnw_facet-search .cnw_facet-label.active span {
	text-decoration: underline;
}

.cnw_facetedsearch .cnw_facet-search .cnw_facet-label.active a {
	display: none;
}
