.teaser-a {
	background-color: var(--color-orange-10);
	border-radius: var(--border-radius-10);
}

.teaser-a__link {
	display: block;
	text-decoration: none;
	height: 100%;
}

/* #region Top */
.teaser-a__top {
	position: relative;
	min-height: 4.25rem;
}

/* #region Thumbnail */
.teaser-a__thumbnail-container {
	border-top-left-radius: var(--border-radius-10);
	border-top-right-radius: var(--border-radius-10);
	overflow: hidden;
}

.teaser-a__thumbnail {
	transition: transform 384ms ease-in;
	width: 100%;
}

.teaser-a:hover .teaser-a__thumbnail {
	transform: scale(1.055);
}
/* #endregion Thumbnail */

/* #region Icon */
.teaser-a__icon {
	background-color: var(--color-white);
	border-radius: var(--border-radius-9999);
	position: absolute;
	top: var(--space-24--rem);
	left: var(--space-24--px);
	width: 2.75rem;
	height: 2.75rem;
}

.teaser-a__icon::before {
	background-color: var(--color-brown);
	content: "";
	display: block;
	mask-repeat: no-repeat;
	mask-size: contain;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1.5rem;
	height: 1.5rem;
}

.teaser-a__icon[data-icon="krijg-ondersteuning-wonen"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-wonen.svg");
}

.teaser-a__icon[data-icon="krijg-ondersteuning-administatie-en-geld"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-administatie-en-geld.svg");
}

.teaser-a__icon[data-icon="krijg-ondersteuning-dagdagelijkse-dingen"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-dagdagelijkse-dingen.svg");
}

.teaser-a__icon[data-icon="krijg-ondersteuning-je-dag-invullen"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-je-dag-invullen.svg");
}

.teaser-a__icon[data-icon="krijg-ondersteuning-opvoeding-en-opvang"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-opvoeding-en-opvang.svg");
}

.teaser-a__icon[data-icon="krijg-ondersteuning-voor-doven-en-slechthorenden"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-voor-doven-en-slechthorenden.svg");
}

.teaser-a__icon[data-icon="krijg-ondersteuning-voor-jezelf-zorgen"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-voor-jezelf-zorgen.svg");
}

.teaser-a__icon[data-icon="krijg-ondersteuning-werken"]::before {
	mask-image: url("../../../images/krijg-ondersteuning-werken.svg");
}

.teaser-a__icon[data-icon="oranje-expertise"]::before {
	mask-image: url("../../../images/oranje-expertise.svg");
}

.teaser-a__icon[data-icon="oranje-ondersteuning-geven"]::before {
	mask-image: url("../../../images/oranje-ondersteuning-geven.svg");
}

.teaser-a__icon[data-icon="oranje-samen-ontspannen"]::before {
	mask-image: url("../../../images/oranje-samen-ontspannen.svg");
}

.teaser-a__icon[data-icon="oranje-samenwerken-puzzel"]::before {
	mask-image: url("../../../images/oranje-lokale-projecten.svg");
}

.teaser-a__icon[data-icon="oranje-samenwerken"]::before {
	mask-image: url("../../../images/oranje-samenwerken.svg");
}

.teaser-a__icon[data-icon="vrije-tijd-bijleren"]::before {
	mask-image: url("../../../images/vrije-tijd-bijleren.svg");
}

.teaser-a__icon[data-icon="vrije-tijd-ontmoeten"]::before {
	mask-image: url("../../../images/vrije-tijd-ontmoeten.svg");
}

.teaser-a__icon[data-icon="vrije-tijd-op-vakantie"]::before {
	mask-image: url("../../../images/vrije-tijd-op-vakantie.svg");
}

.teaser-a__icon[data-icon="vrije-tijd-samen-spelen"]::before {
	mask-image: url("../../../images/vrije-tijd-samen-spelen.svg");
}

.teaser-a__icon[data-icon="vrije-tijd-sporten"]::before {
	mask-image: url("../../../images/vrije-tijd-sporten.svg");
}
/* #endregion Icon */
/* #endregion Top */

/* #region Bottom */
.teaser-a__bottom {
	padding-inline: var(--space-24--px);
	padding-block: var(--space-24--rem);
}

.teaser-a__title {
	color: var(--color-brown);
	margin-block-start: 0;
	text-decoration: underline;
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.teaser-a:hover .teaser-a__title {
	text-decoration-color: var(--color-orange);
}

.teaser-a__excerpt {
	margin-block-end: 0;
}
/* #endregion Bottom */
