.uit-archive .cnw_row {
	margin: 0;
}

.uit-archive .cnw_col-4,
.uit-archive .cnw_col-8,
.uit-archive .cnw_col-12 {
	padding-inline: 0;
	max-width: 100%;
}

/* #region GRID */
.uit-archive .cnw_container-fluid > .cnw_row {
	display: grid;
	grid-row-gap: var(--row-gap);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.uit-archive .cnw_container-fluid > .cnw_row {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.uit-archive .cnw_container-fluid > .cnw_row {
		grid-row-gap: var(--space-56--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.uit-archive .cnw_container-fluid > .cnw_row {
		grid-column-gap: var(--column-gap);
		grid-template-columns: 18.625rem 1fr;
	}
}
/* #endregion GRID */

/* #region Column (left) */
.uit-archive .cnw_col-4 .cnw_facets_content {
	display: grid !important;
	grid-row-gap: var(--space-24--rem);
	margin: 0;
}

.uit-archive .cnw_col-4 .cnw_facetedsearch {
	margin-block: 0;
}

.uit-archive .cnw_col-4 .cnw_facets_toggle {
	display: none;
	margin: 0;
}
/* #endregion Column (left) */

/* #region Column (right) */
.uit-archive .cnw_col-8 .cnw_searchresult__counter {
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.uit-archive .cnw_col-8 .cnw_searchresult__counter {
		margin-block-end: var(--space-40--rem);
	}
}
/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.uit-archive .cnw_col-8 .cnw_searchresult__counter {
		margin-block-end: var(--space-56--rem);
	}
}

.uit-archive .cnw_col-8 .cnw_searchresult__counter .cnw_searchresult__counter-text {
	margin: 0;

	/* type-d */
	font-family: var(--font-sans);
	font-size: 1.25rem !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 1.3;
}

/* #region Search results */
.uit-archive .cnw_col-8 .cnw_searchresult__searchwords {
	margin-block-end: var(--space-24--rem);
}

.uit-archive .cnw_col-8 .cnw_searchresult__searchwords p {
	display: flex;
	flex-direction: column;
	margin: 0;
	align-items: flex-start;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.uit-archive .cnw_col-8 .cnw_searchresult__searchwords p {
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: var(--space-8--rem);
		column-gap: var(--space-8--px);
		align-items: center;
	}
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.uit-archive .cnw_col-8 .cnw_searchresult__results .cnw_searchresult__searchwords .cnw_badge {
		margin-block-start: var(--space-8--rem);
	}
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.uit-archive .cnw_col-8 .cnw_searchresult__searchwords a[data-active-keywords-name="all"] {
		margin-block-start: var(--space-24--rem);
	}
}

.uit-archive .cnw_col-8 .cnw_searchresult__searchwords + .cnw_searchresult__counter {
	border-top: 1px solid var(--color-grey-light);
	padding-block-start: var(--space-24--rem);
}

@media (prefers-contrast: more) {
	.uit-archive .cnw_col-8 .cnw_searchresult__searchwords + .cnw_searchresult__counter {
		border-top: 1px solid rgb(177, 177, 177);
	}
}
/* #endregion Search results */

.uit-archive .cnw_col-8 .cnw_searchresult__results .cnw_searchresult--block {
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.uit-archive .cnw_col-8 .cnw_searchresult__results .cnw_searchresult--block {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.uit-archive .cnw_col-8 .cnw_searchresult__results .cnw_searchresult--block {
		margin-block-end: var(--space-56--rem);
	}
}

.uit-archive .cnw_col-8 .cnw_searchresult .cnw_pagination {
	margin: 0;
	justify-content: flex-start;
}
/* #endregion Column (right) */
