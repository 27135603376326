.sub-navigation {
	border-top: 0.375rem solid var(--color-orange);
	padding-block-start: var(--space-24--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.sub-navigation__menu {
		border-left: 1px solid var(--color-grey-light);
		padding-inline-start: var(--space-24--px);
	}
}

.sub-navigation__menu__title {
	font-weight: 400;
	margin-block-end: var(--space-8--rem);
}

.sub-navigation__menu__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sub-navigation__menu__list__item:not(:last-child) {
	margin-block-end: var(--space-8--rem);
}

.sub-navigation__menu__list__link {
	color: var(--color-brown);
}

.sub-navigation__menu__list__link:is(:hover, :active) {
	color: var(--color-black);
}

.sub-navigation__menu__list__item[data-is-current] .sub-navigation__menu__list__link {
	color: var(--color-black);
	text-decoration: none;
}
