.cnw_w .cnw_block .cnw_card-description {
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--space-8--px);
	column-gap: var(--space-8--px);
	margin-block-start: 0;
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.cnw_w .cnw_block .cnw_card-description {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_w .cnw_block .cnw_card-description {
		margin-block-end: var(--space-56--rem);
	}
}

.cnw_card-description .cnw_badge {
	background-color: transparent;
	border: 0.125rem solid var(--color-blue-light);
	border-radius: var(--border-radius-5);
	color: var(--color-blue-dark);
	display: inline-block;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-6--rem);

	/* type-h */
	font-family: var(--font-body-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
}

.cnw_card-description .cnw_badge .cnw-fa {
	display: none;
}
