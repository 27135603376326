.l-archive {
}

.l-archive__columns {
	display: grid;
	grid-row-gap: var(--space-32--rem);
	align-items: flex-start;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-archive__columns {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-archive__columns {
		grid-row-gap: var(--space-56--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.l-archive__columns {
		grid-column-gap: var(--column-gap);
		grid-template-columns: 18.625rem 1fr;
	}
}

.l-archive__column__title {
	color: var(--color-brown);
}

.l-archive__column__searched {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-archive__column__searched {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
	}
}

.l-archive__column__searched__title {
	margin-block-start: 0;
	margin-block-end: var(--space-8--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-archive__column__searched__title {
		margin-inline-end: var(--space-8--px);
		margin-block-end: 0;
	}
}

.l-archive__column__searched__terms {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-8--rem);
	column-gap: var(--space-8--px);
	margin-block-end: var(--space-24--rem);
	align-items: flex-start;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-archive__column__searched__terms {
		flex-direction: row;
		flex-wrap: wrap;
		margin-inline-end: var(--space-8--px);
		margin-block-end: 0;
	}
}

/* #region Title */
.l-archive__column__title {
	margin-block-start: 0;
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-archive__column__title {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-archive__column__title {
		margin-block-end: var(--space-56--rem);
	}
}
/* #endregion Title */

/* #region Pagination */
.l-archive__column .pagination {
	margin-block-start: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-archive__column .pagination {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-archive__column .pagination {
		margin-block-start: var(--space-56--rem);
	}
}
/* #endregion Pagination */
