@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

/*
 * Give "display: none;" for elements with a "hidden" attribute specificity [0, 2, 0].
 * This will generally be higher than layout directives : "display: flex".
 * Because this is declared very early you can easily override it with ".foo[hidden]".
 */
[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	text-underline-position: from-font;
	min-height: 100vh;
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: var(--color-grey-light);
	border: none;
	margin-block: var(--space-24--rem);
	height: var(--space-1--rem);
}

@media (prefers-contrast: more) {
	hr {
		background-color: rgb(177, 177, 177); /* color-mix(in oklch, rgb(204, 204, 204), black 10%) */
	}
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin-inline: 0;
	margin-block: var(--space-32--rem);
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-block-start: 0;
}

table:last-child {
	margin-block-end: 0;
}

th,
td {
	box-sizing: border-box;
	padding-inline: var(--space-32--px);
	padding-block: var(--space-32--rem);
	text-align: left;
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin: 0;
	margin-block-end: var(--space-24--rem);
	padding-inline-start: 1.25rem;
}

:is(ol, ul):not([class]):last-child {
	margin-block-end: 0;
}

li :is(ol, ul):not([class]) {
	margin-block-end: 0;
	padding-block-start: 0.25rem;
}

li:not([class]) {
	margin: 0;
	padding: 0 0 0.25rem 0;
	position: relative;
}

/* #region Shared link styles (WYSIWYG and components) */
:any-link {
	color: currentColor;
	transition: color 96ms linear;
}

/* #endregion Shared link styles (WYSIWYG and components) */

/* #region WYSIWYG link styles */
:any-link:not([class]) {
	display: inline;
	font-weight: 600;
	text-decoration: underline;
}

:any-link:not([class]):hover {
	color: var(--color-blue-dark);
}

p a:not([class]) {
	line-height: inherit;
}
/* #endregion WYSIWYG link styles */

.wp-block-table {
	margin: 0; /* Remove margin from Figure tag set by Gutenberg */
}

pre {
	white-space: pre-wrap;
}

.mr-table-scroll-container {
	overflow-x: auto;
}
