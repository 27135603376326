.navigation-overlay {
	background-color: var(--color-background);
	display: grid;
	grid-template-rows: auto auto 1fr;
	width: 100%;
	height: 100%;
}

.navigation-overlay__header {
	display: flex;
	justify-content: space-between;
	position: relative;
	height: 5.5rem;
}

.navigation-overlay__header__logo {
	background-image: var(--theme-logo);
	background-repeat: no-repeat;
	display: block;
	width: 5.9375rem;
	height: 8rem;
	z-index: 1;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.navigation-overlay__header__logo {
		width: 6.875rem;
		height: 9.25rem;
	}
}

/* #region Primary Navigation */
.navigation-overlay__primary {
	background-color: var(--color-orange-10);
	opacity: 0;
	padding-block-start: calc(var(--space-80--rem) - var(--space-16--rem));
	padding-block-end: var(--space-32--rem);
	transition: opacity 176ms ease-in-out;
}

.modal-dialog[data-state="open"] .navigation-overlay__primary {
	opacity: 1;
}

.navigation-overlay__primary__list {
	display: grid;
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.navigation-overlay__primary__list {
		grid-column-gap: var(--space-32--px);
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
}

.navigation-overlay__primary__list__link {
	border-bottom: 3px solid transparent;
	display: flex;
	padding-block: var(--space-16--rem);
	position: relative;
	text-decoration: none;
	transition:
		color 176ms ease-in-out,
		border-bottom 176ms ease-in-out;
}

.navigation-overlay__primary__list__link:hover,
.navigation-overlay__primary__list__item[data-is-current] .navigation-overlay__primary__list__link {
	border-bottom: 3px solid var(--color-orange);
	color: var(--color-brown);
}

.navigation-overlay__primary__list__link::after {
	border-bottom: 1px solid var(--color-grey-light);
	content: "";
	display: block;
	position: absolute;
	right: 0;
	bottom: -2px;
	left: 0;
	transition: border-bottom-color 176ms ease-in-out;
	width: 100%;
}

.navigation-overlay__primary__list__link:hover::after,
.navigation-overlay__primary__list__item[data-is-current] .navigation-overlay__primary__list__link::after {
	border-bottom-color: transparent;
}

.navigation-overlay__primary__list__link .icon-search {
	display: block;
	margin-inline-start: var(--space-8--px);
	width: var(--space-24--rem);
	height: var(--space-24--rem);
}
/* #endregion Primary Navigation */

/* #region Secondary Navigation */
.navigation-overlay__secondary {
	background-image: var(--theme-gradient);
	color: var(--theme-navigation-overlay-secondary-text-color);
	opacity: 0;
	padding-block: var(--space-32--rem);
	transition: opacity 176ms ease-in-out;
	transition-delay: 96ms;
}

.modal-dialog[data-state="open"] .navigation-overlay__secondary {
	opacity: 1;
}

.navigation-overlay__secondary__list {
	display: grid;
	grid-row-gap: var(--space-16--rem);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.navigation-overlay__secondary__list {
		grid-column-gap: var(--space-32--px);
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
}

.navigation-overlay__secondary__list__link {
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.navigation-overlay__secondary__list__link:hover,
.navigation-overlay__secondary__list__item[data-is-current] .navigation-overlay__secondary__list__link {
	text-decoration-color: currentColor;
}

/* #endregion Secundary Navigation */

/* #region Hamburger */
.navigation-overlay__header__hamburger {
	-mrh-resets: button;
	background-color: var(--color-orange-35);
	border-radius: var(--border-radius-5);
	display: flex;
	flex-direction: column;
	margin-top: var(--space-16--rem);
	padding: 1.375rem 19px;
	justify-content: space-between;
	align-items: center;
	transition: background-color 176ms ease-in-out;
	width: 3.5rem;
	height: 3.5rem;
}

.modal-dialog:not([data-state="open"]) .navigation-overlay__header__hamburger,
.navigation-overlay__header__hamburger:hover,
.site-header[data-has-hero] .navigation-overlay__header__hamburger:hover {
	background-color: var(--color-orange);
}

.navigation-overlay__header__hamburger__line {
	background-color: var(--color-black);
	display: block;
	transition:
		opacity 176ms ease-in-out,
		transform 176ms ease-in-out;
	width: 1.125rem;
	height: 0.125rem;
}

.modal-dialog[data-animation-start] .navigation-overlay__header__hamburger__line:first-child {
	transform: rotate(45deg) translate(0.25rem, 0.25rem);
	transform-origin: center;
}

.modal-dialog[data-animation-start] .navigation-overlay__header__hamburger__line:last-child {
	transform: rotate(-45deg) translate(0.1875em, -0.1875rem);
	transform-origin: center;
}

.modal-dialog[data-animation-start] .navigation-overlay__header__hamburger__line:nth-child(2) {
	opacity: 0;
}

/* #endregion Hamburger */
