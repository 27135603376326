@font-face {
	font-family: "GT Pressura Fallback";
	font-style: normal;
	font-weight: 700;
	src: local(Arial Bold);
	ascent-override: 120.34%;
	descent-override: 36.18%;
	line-gap-override: 0%;
	size-adjust: 89.75%;
}

@font-face {
	font-family: "GT Pressura";
	src: url("../../fonts/common/gt-pressura-bold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00EB, U+00ED-00EF, U+00F1-00F6, U+00F8-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117, U+0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+1ED3, U+200B, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "GT Pressura";
	src: url("../../fonts/extended/gt-pressura-bold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00EC-00EC, U+00F0-00F0, U+00F7-00F7, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-1ED2, U+1ED4-200A, U+200C-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Noto Sans Fallback";
	font-style: normal;
	font-weight: 400;
	src: local(Arial);
	ascent-override: 100.41%;
	descent-override: 27.52%;
	line-gap-override: 0%;
	size-adjust: 106.47%;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/common/notosans-regular-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00EB, U+00ED-00EF, U+00F1-00F6, U+00F8-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117, U+0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+1ED3, U+200B, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/extended/notosans-regular-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00EC-00EC, U+00F0-00F0, U+00F7-00F7, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-1ED2, U+1ED4-200A, U+200C-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Noto Sans Fallback";
	font-style: italic;
	font-weight: 400;
	src: local(Arial Italic);
	ascent-override: 106.59%;
	descent-override: 29.21%;
	line-gap-override: 0%;
	size-adjust: 100.3%;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/common/notosans-italic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00EB, U+00ED-00EF, U+00F1-00F6, U+00F8-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117, U+0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+1ED3, U+200B, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/extended/notosans-italic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00EC-00EC, U+00F0-00F0, U+00F7-00F7, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-1ED2, U+1ED4-200A, U+200C-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Noto Sans Fallback";
	font-style: normal;
	font-weight: 600;
	src: local(Arial Bold);
	ascent-override: 104.19%;
	descent-override: 28.56%;
	line-gap-override: 0%;
	size-adjust: 102.6%;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/common/notosans-semibold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00EB, U+00ED-00EF, U+00F1-00F6, U+00F8-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117, U+0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+1ED3, U+200B, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/extended/notosans-semibold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00EC-00EC, U+00F0-00F0, U+00F7-00F7, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-1ED2, U+1ED4-200A, U+200C-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Noto Sans Fallback";
	font-style: italic;
	font-weight: 600;
	src: local(Arial Bold Italic);
	ascent-override: 110.5%;
	descent-override: 30.29%;
	line-gap-override: 0%;
	size-adjust: 96.74%;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/common/notosans-semibolditalic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00EB, U+00ED-00EF, U+00F1-00F6, U+00F8-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117, U+0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+1ED3, U+200B, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/extended/notosans-semibolditalic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00EC-00EC, U+00F0-00F0, U+00F7-00F7, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-1ED2, U+1ED4-200A, U+200C-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Noto Sans Fallback";
	font-style: normal;
	font-weight: 700;
	src: local(Arial Bold);
	ascent-override: 101.67%;
	descent-override: 27.87%;
	line-gap-override: 0%;
	size-adjust: 105.14%;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/common/notosans-bold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00EB, U+00ED-00EF, U+00F1-00F6, U+00F8-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117, U+0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+1ED3, U+200B, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/extended/notosans-bold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00EC-00EC, U+00F0-00F0, U+00F7-00F7, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-1ED2, U+1ED4-200A, U+200C-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Noto Sans Fallback";
	font-style: italic;
	font-weight: 700;
	src: local(Arial Bold Italic);
	ascent-override: 107.76%;
	descent-override: 29.54%;
	line-gap-override: 0%;
	size-adjust: 99.2%;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/common/notosans-bolditalic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00EB, U+00ED-00EF, U+00F1-00F6, U+00F8-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117, U+0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+1ED3, U+200B, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Noto Sans";
	src: url("../../fonts/extended/notosans-bolditalic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00EC-00EC, U+00F0-00F0, U+00F7-00F7, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-1ED2, U+1ED4-200A, U+200C-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}
