.cnw_row.cnw_searchresult-detail {
	display: grid;
	grid-row-gap: var(--row-gap);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.cnw_row.cnw_searchresult-detail {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_row.cnw_searchresult-detail {
		grid-row-gap: var(--space-56--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.cnw_row.cnw_searchresult-detail {
		grid-column-gap: var(--space-32--rem);
		grid-template-columns: 1fr 32.3125rem;
	}
}

.cnw_row.cnw_searchresult-detail .cnw_col-4 {
	grid-row: 2;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-4 {
		grid-row: 1;
		grid-column: 2;
	}
}

.cnw_row.cnw_searchresult-detail .cnw_col-8 {
	grid-row: 1;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-8 {
		grid-column: 1;
	}
}

/* #region Title */
.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_page-title {
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_page-title {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_page-title {
		margin-block-end: var(--space-56--rem);
	}
}
/* #endregion Title */

/* #region Stickers */
.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_stickers--detail {
	display: none;
}
/* #endregion Stickers */

/* #region Summary */
.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_detail-summary {
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_detail-summary {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_detail-summary {
		margin-block-end: var(--space-56--rem);
	}
}
/* #endregion Summary */

/* #region Back */
.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_searchresult-detail-back-button {
	margin-block-start: var(--space-32--rem);

	/* type-f */
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_searchresult-detail-back-button {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_row.cnw_searchresult-detail .cnw_col-8 .cnw_searchresult-detail-back-button {
		margin-block-start: var(--space-56--rem);
	}
}
/* #endregion Back */
