.cnw_searchresult--block.cnw_row {
	background-color: var(--color-blue-light-20);
	border-radius: var(--border-radius-10);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	row-gap: var(--space-24--rem);
	padding-inline: var(--space-24--px);
	padding-block: var(--space-24--px);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult--block.cnw_row {
		flex-direction: row;
		column-gap: var(--space-24--px);
		align-items: flex-start;
	}
}

.cnw_searchresult--block .cnw_col-3 {
	flex: none;
	max-width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult--block .cnw_col-3 {
		max-width: 11.75rem;
		max-height: 11.75rem;
	}
}

.cnw_searchresult--block .cnw_col-9 {
	flex: auto;
	max-width: 100%;
}

.cnw_searchresult--block .cnw_col-3,
.cnw_searchresult--block .cnw_col-9 {
	padding: 0;
}

.cnw_searchresult--block .cnw_col-3 .cnw_img_responsive {
	aspect-ratio: 16 / 9;
	margin: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult--block .cnw_col-3 .cnw_img_responsive {
		aspect-ratio: 1 / 1;
	}
}

@media (prefers-contrast: more) {
	.cnw_searchresult--block {
		outline: 1px solid var(--color-blue-dark);
		outline: 1px solid lab(from var(--color-blue-dark) l a b / 0.75);
	}
}

.cnw_searchresult--block .cnw_card-block .cnw_card-title {
	font-size: 1.5rem;
	line-height: 1.25;
}

.cnw_searchresult--block .cnw_card-block .cnw_card-title a {
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.cnw_searchresult--block .cnw_card-block .cnw_card-title a:hover {
	text-decoration-color: var(--color-blue-dark);
}

.cnw_searchresult--block .cnw_card-block .cnw_stickers {
	margin: 0;
	padding: 0;
}

.cnw_searchresult--block .cnw_card-block .cnw_card-description {
	display: block;
	margin-block-start: 0;

	/* type-f */
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
}

/* #region Labels */
.cnw_searchresult--block .cnw_card-block .cnw_card-description .cnw_badge {
	background-color: transparent;
	border: 0.125rem solid var(--color-blue-light);
	border-radius: var(--border-radius-5);
	color: var(--color-blue-dark);
	display: inline-block;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-6--rem);
	text-align: left;
	text-wrap: pretty;

	/* type-h */
	font-family: var(--font-body-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
}

.cnw_searchresult--block .cnw_card-block .cnw_card-description .cnw_badge:not(:last-child) {
	margin-inline-end: var(--space-4--px);
	margin-block-end: var(--space-8--rem);
}

.cnw_searchresult--block .cnw_card-block .cnw_card-description .cnw_badge .cnw-fa {
	display: none;
}
/* #endregion Labels */

/* #region List items */
.cnw_searchresult--block .cnw_card-block .list {
	background-color: var(--color-white);
	margin-block-end: var(--space-24--rem);
	padding-inline: var(--space-16--px);
	padding-block: var(--space-16--px);
	width: 100%;
}

@media (prefers-contrast: more) {
	.cnw_searchresult--block .cnw_card-block .list {
		outline: 1px solid var(--color-blue-dark);
		outline: 1px solid lab(from var(--color-blue-dark) l a b / 0.6);
	}
}

.cnw_searchresult--block .cnw_card-block .list .list-item {
	display: flex;
	flex-direction: column;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult--block .cnw_card-block .list .list-item {
		flex-direction: row;
	}
}

.cnw_searchresult--block .cnw_card-block .list .list-item:not(:last-child) {
	margin-block-end: var(--space-8--rem);
}

.cnw_searchresult--block .cnw_card-block .list .list-item .list-item-aside,
.cnw_searchresult--block .cnw_card-block .list .list-item .list-item-content {
	/* type-h */
	font-family: var(--font-body-sans);
	font-size: 0.875rem;
	font-style: normal;
	line-height: 1.5;
}

.cnw_searchresult--block .cnw_card-block .list .list-item .list-item-aside {
	flex-shrink: 0;
	font-weight: 600;
	margin-inline-end: var(--space-8--px);
	width: 9.375rem;
}

.cnw_searchresult--block .cnw_card-block .list .list-item .cnw-fa {
	display: none;
}

.cnw_searchresult--block .cnw_card-block .list .list-item .cnw_searchresult-detail-label {
	clip: initial;
	clip-path: initial;
	font-style: normal;
	position: relative;
	width: auto;
	height: auto;
}

.cnw_searchresult--block .cnw_card-block .list .list-item .list-item-content {
	padding: 0;
}

.cnw_searchresult--block .cnw_card-block .list .list-item .list-item-content p {
	margin: 0;
}
/* #endregion List items */

.cnw_searchresult--block .cnw_card-block .cnw_card-labels {
	display: none;
}

.cnw_searchresult--block .cnw_card-block .cnw_btn__card-readmore {
	border: none;
	padding: 0;

	/* type-f */
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
	transition: text-decoration-color 176ms ease-in-out;
}

.cnw_searchresult--block .cnw_card-block .cnw_btn__card-readmore:hover {
	text-decoration: underline;
	text-decoration-color: transparent;
}
