.cnw_row.cnw_searchresult-detail .cnw_searchresult-detail-video-wrapper {
	aspect-ratio: 16 / 9;
}

.cnw_row.cnw_searchresult-detail .cnw_searchresult-detail-video-wrapper iframe {
	border-radius: var(--border-radius-10);
	width: 100%;
	height: 100%;
}

@media (prefers-contrast: more) {
	.cnw_row.cnw_searchresult-detail .cnw_searchresult-detail-video-wrapper iframe {
		outline: 1px solid var(--color-blue-dark);
		outline: 1px solid lab(from var(--color-blue-dark) l a b / 0.6);
	}
}
