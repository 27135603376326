.l-landing {
}

.l-landing__teasers {
	display: grid;
	grid-row-gap: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-landing__teasers {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-landing__teasers {
		grid-row-gap: var(--space-56--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.l-landing__teasers {
		grid-column-gap: var(--column-gap);
		grid-template-columns: 298px 1fr;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.l-landing__teasers__list {
		grid-row: 1;
		grid-column: 2;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.l-landing__teasers__link {
		grid-row: 1;
	}
}
