.l-search {
}

/* #region Title */
.l-search__title {
	color: var(--color-brown);
	margin-block-start: 0;
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-search__title {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-search__title {
		margin-block-end: var(--space-56--rem);
	}
}
/* #endregion Title */

/* #region Pagination */
.l-search__pagination {
	margin-block-start: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-search__pagination {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-search__pagination {
		margin-block-start: var(--space-56--rem);
	}
}
/* #endregion Pagination */
