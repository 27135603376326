.teaser-c {
	background-color: var(--color-orange-10);
	border-radius: var(--border-radius-10);
}

.teaser-c__link {
	display: block;
	padding-inline: var(--space-24--px);
	padding-block: var(--space-24--rem);
	text-decoration: none;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-c__link {
		display: flex;
	}
}

.teaser-c__thumbnail-container {
	margin-block-end: var(--space-24--rem);
	overflow: hidden;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-c__thumbnail-container {
		flex-shrink: 0;
		margin-inline-end: var(--space-24--px);
		margin-block-end: 0;
		width: 11.75rem;
		height: 11.75rem;
	}
}

.teaser-c__thumbnail {
	transition: transform 384ms ease-in;
	width: 100%;
}

.teaser-c:hover .teaser-c__thumbnail {
	transform: scale(1.055);
}

.teaser-c__title {
	color: var(--color-brown);
	margin-block-start: 0;
	text-decoration: underline;
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.teaser-c:hover .teaser-c__title {
	text-decoration-color: var(--color-orange);
}

.teaser-c__labels {
	display: flex;
	flex-wrap: wrap;
}

.teaser-c__label {
	background-color: transparent;
	border: 0.125rem solid var(--color-orange);
	border-radius: var(--border-radius-5);
	color: var(--color-brown);
	display: inline-block;
	margin-block-end: var(--space-8--rem);
	padding-inline: var(--space-16--px);
	padding-block: var(--space-6--rem);
}

.teaser-c__label:not(:last-child) {
	margin-inline-end: var(--space-8--px);
}

.teaser-c__label[data-variant="date"] {
	border-color: var(--color-brown);
}

.teaser-c__excerpt {
	margin-block-start: var(--space-16--rem);
	margin-block-end: 0;
}
