.location-info {
}

.location-info__list {
	border: 0.125rem solid var(--color-orange);
	border-radius: var(--border-radius-10);
	box-sizing: border-box;
	margin-block-start: var(--space-32--rem);
	margin-block-end: 0;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.location-info__list {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.location-info__list {
		margin-block-start: var(--space-56--rem);
	}
}

.location-info__list__item {
	padding-inline: var(--space-24--px);
	padding-block: var(--space-16--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.location-info__list__item {
		display: flex;
	}
}

.location-info__list__item:not(:last-child) {
	border-bottom: 0.0625rem solid var(--color-orange);
}

.location-info__list__item > dt {
	font-weight: 700;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.location-info__list__item > dt {
		margin-block-end: var(--space-16--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.location-info__list__item > dt {
		flex-shrink: 0;
		padding-inline-end: var(--space-24--px);
		width: 9.25rem;
	}
}

.location-info__list__item > dd {
	box-sizing: border-box;
	margin: 0;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.location-info__list__item > dd {
		flex-grow: 1;
		flex-shrink: 0;
		padding-inline-start: var(--space-24--px);
		text-align: right;
		width: 24.5rem;
	}

	.location-info__list__item ul li::marker {
		color: transparent;
	}

	.location-info__list__item ul li::before {
		content: "•";
		font-weight: 700;
		margin-inline-end: 10px;
	}
}

.location-info__list__item p:first-child {
	margin-block-start: 0;
}

.location-info__list__item p:last-child {
	margin-block-end: 0;
}
