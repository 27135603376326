.cnw_row.cnw_searchresult-detail .cnw_img_responsive {
	border-radius: var(--border-radius-10);
	margin: 0;
}

@media (prefers-contrast: more) {
	.cnw_row.cnw_searchresult-detail .cnw_img_responsive {
		outline: 1px solid var(--color-blue-dark);
		outline: 1px solid lab(from var(--color-blue-dark) l a b / 0.6);
	}
}

.cnw_row.cnw_searchresult-detail .cnw_copyright {
	margin-block-start: var(--space-24--rem);
	margin-block-end: 0;

	/* type-h */
	font-family: var(--font-body-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
}
