.list-teasers-d {
}

.list-teasers-d__items {
	display: grid;
	grid-row-gap: var(--row-gap);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.list-teasers-d__items {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}
