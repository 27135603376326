.testimonial {
	background-color: var(--color-blue-light-20);
	border-radius: var(--border-radius-10);
}

.testimonial__inner {
	box-sizing: border-box;
	padding-inline: var(--space-24--px);
	padding-block: var(--space-56--rem);
	width: 100%;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.testimonial__inner {
		padding-inline: var(--space-80--px);
		padding-block: var(--space-80--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.testimonial__inner {
		display: grid;
		grid-column-gap: var(--space-32--px);
		grid-template-columns: repeat(2, minmax(0, 1fr));
		padding-inline: 28px;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.testimonial__inner {
		padding-inline: 66px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.testimonial__inner {
		margin: 0 auto;
		padding-inline: 0;
		max-width: 80.5rem;
	}
}

/* #region Media */
.testimonial__media {
	margin-inline: 0;
	margin-block-start: 0;
	margin-block-end: var(--space-24--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.testimonial__media {
		margin-block-end: 0;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.testimonial__media {
		grid-row: 1;
		grid-column: 2;
	}
}

.testimonial__image {
	width: 100%;
}
/* #endregion Media */

/* #region Content */
/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.testimonial__content {
		grid-row: 1;
		grid-column: 1;
		padding-inline: var(--space-56--px);
		padding-block: var(--space-56--rem);
		align-self: center;
	}
}

.testimonial__content__title {
	color: var(--color-blue-dark);
	text-wrap: balance;
}
/* #endregion Content */
