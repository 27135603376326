.anchor-links {
	border-left: 0.1875rem solid var(--color-orange);
	display: block;
	padding-inline-start: var(--space-24--px);
}

.anchor-links__title {
	font-weight: 400;
	margin-block-end: var(--space-8--rem);
}

.anchor-links__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.anchor-links__list__item:not(:last-child) {
	margin-block-end: var(--space-8--rem);
}

.anchor-links__list__link {
	color: var(--color-brown);
}

.anchor-links__list__link:is(:hover, :active) {
	color: var(--color-black);
}
