.list-teasers-c {
}

.list-teasers-c__items {
	display: grid;
	grid-row-gap: var(--row-gap);
	list-style-type: none;
	margin: 0;
	padding: 0;
}
