/* #region Title */
.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-block-title {
	border-bottom: none;
	color: var(--color-blue-dark);
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.3;
	margin-block-start: 0;
	margin-block-end: var(--space-32--rem);
	padding: 0;
	text-transform: capitalize;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-block-title {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-block-title {
		margin-block-end: var(--space-56--rem);
	}
}
/* #endregion Title */

/* #region List */
.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info {
	border: 0.125rem solid var(--color-blue-light);
	border-radius: var(--border-radius-10);
	display: grid;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info {
		grid-template-columns: 11.75rem minmax(0, 1fr);
	}
}

.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info {
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info {
		margin-block-end: var(--space-56--rem);
	}
}

.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dt,
.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dd {
	max-width: 100%;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info .cnw_searchresult-detail-link {
	white-space: normal;
}

.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dt {
	display: flex;
	padding-inline: var(--space-24--px);
	padding-block: var(--space-16--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dt:not(:nth-last-child(2)) {
		border-bottom: 0.0625rem solid var(--color-blue-light);
	}
}

.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dd {
	margin: 0;
	padding-inline: var(--space-24--px);
	padding-block-end: var(--space-16--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dd {
		padding-block-start: var(--space-16--rem);
		text-align: right;
	}
}

.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dd:not(:last-child) {
	border-bottom: 0.0625rem solid var(--color-blue-light);
}

.cnw_searchresult-detail .cnw_block .cnw_searchresult-detail-info dd p {
	margin: 0;
}

.cnw_w .cnw_searchresult-detail-info dt .cnw-fa {
	display: none;
}

.cnw_w .cnw_searchresult-detail-info em.cnw_searchresult-detail-label {
	clip: initial;
	clip-path: initial;
	font-style: normal;
	position: relative;
	width: auto;
	height: auto;
}

/* #region List */
.cnw_w .cnw_searchresult-detail-info ul {
	margin: 0;
	padding-inline-start: 1.25rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_w .cnw_searchresult-detail-info ul li::marker {
		color: transparent;
	}

	.cnw_w .cnw_searchresult-detail-info ul li::before {
		content: "•";
		font-weight: 700;
		margin-inline-end: 10px;
	}
}
/* #endregion List */

.cnw_w .cnw_searchresult-detail-info .cnw_searchresult-detail-link {
	font-weight: 600;
}

.cnw_w .cnw_searchresult-detail-info .cnw_searchresult-detail-link:hover {
	color: var(--color-blue-dark);
}
