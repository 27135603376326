.cnw_w.cnw_w * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body .cnw_w.cnw_w {
	color: var(--color-black);
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	line-height: 1.5; /* 150% */
}

.cnw_w.cnw_w h1,
.cnw_w.cnw_w h2,
.cnw_w.cnw_w h3,
.cnw_w.cnw_w h4,
.cnw_w.cnw_w h5,
.cnw_w.cnw_w h6 {
	color: var(--color-blue-dark);
	font-family: var(--font-sans);
	text-wrap: balance;
}

.cnw_w.cnw_w h1 {
	margin-block-start: var(--space-56--rem);
	margin-block-end: var(--space-56--rem);
}

.cnw_w.cnw_w h2,
.cnw_w.cnw_w h3,
.cnw_w.cnw_w h4,
.cnw_w.cnw_w h5,
.cnw_w.cnw_w h6 {
	margin-block-start: var(--space-56--rem);
	margin-block-end: var(--space-24--rem);
}

html .cnw_w.cnw_w p {
	margin-block: var(--space-24--rem);
}

.cnw_w.cnw_w :is(h1, h2, h3, h4, h5, h6):first-child {
	margin-block-start: 0;
}

.cnw_w.cnw_w :is(h1, h2, h3, h4, h5, h6):last-child {
	margin-block-end: 0;
}

.cnw_w.cnw_w :is(h1, h2, h3, h4, h5, h6):only-child {
	margin-block-start: 0;
	margin-block-end: 0;
}

.cnw_w.cnw_w h1 {
	font-family: var(--font-sans);
	font-size: 2.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.25; /* 125% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cnw_w.cnw_w h1 {
		font-size: 3.75rem;
		line-height: 1.2; /* 120% */
	}
}

.cnw_w.cnw_w h2 {
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.3; /* 130% */
}

.cnw_w.cnw_w h3 {
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.25; /* 125% */
}

.cnw_w.cnw_w h4,
.cnw_w.cnw_w h5,
.cnw_w.cnw_w h6 {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.3; /* 130% */
}

.cnw_w.cnw_w i,
.cnw_w.cnw_w em {
	font-style: italic;
}
