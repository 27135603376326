.site-header {
	position: relative;
}

.site-header[data-has-hero] {
	color: var(--color-white);
}

.site-header__background {
	background-color: var(--color-orange-10);
	border-bottom-left-radius: var(--border-radius-10);
	border-bottom-right-radius: var(--border-radius-10);
	overflow: hidden;
	position: relative;
	min-height: 5.5rem;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-header__background {
		min-height: 6.1875rem;
	}
}

.site-header[data-has-hero] .site-header__background {
	height: 21.875rem;
}

.site-header[data-has-hero] .site-header__background::after {
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 22.44%, rgba(0, 0, 0, 0.1) 100%);
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@media (prefers-contrast: more) {
	.site-header[data-has-hero] .site-header__background::after {
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.1) 100%);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header[data-has-hero] .site-header__background {
		height: 26.875rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-header[data-has-hero] .site-header__background {
		height: 33.75rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header[data-has-hero] .site-header__background {
		height: 37.5rem;
	}
}

.site-header__background__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.site-header__main {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

.site-header__main__inner {
	display: flex;
	justify-content: space-between;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-header__main__inner {
		justify-content: flex-start;
	}
}

.site-header__main__navigation {
	display: none;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-header__main__navigation {
		display: flex;
		flex-flow: column-reverse;
		justify-content: flex-end;
		align-items: center;
		width: 62.375rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header__main__navigation {
		width: 73.625rem;
	}
}

.site-header__logo {
	background-image: var(--theme-logo);
	background-repeat: no-repeat;
	display: block;
	width: 5.9375rem;
	height: 8rem;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-header__logo {
		width: 6.875rem;
		height: 9.25rem;
	}
}

/* #region Navigations */
/* #region Primary Nav */
.site-header__primary-nav {
	background-color: var(--color-orange-35);
	border-radius: var(--border-radius-5);
}

.site-header[data-has-hero] .site-header__primary-nav {
	background-color: var(--color-white);
	color: var(--color-black);
}

.site-header__primary-nav__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	padding-inline: var(--space-16--px);
}

.site-header__primary-nav__list__item {
	margin-inline: var(--space-16--px);
}

.site-header__primary-nav__list__link {
	border-bottom: 3px solid transparent;
	box-sizing: border-box;
	display: flex;
	padding-block-start: var(--space-16--rem);
	padding-block-end: calc(var(--space-16--rem) - 3px);
	text-decoration: none;
	transition:
		color 176ms ease-in-out,
		border-bottom-color 176ms ease-in-out;
}

.site-header__primary-nav__list__link:hover,
.site-header__primary-nav__list__item[data-is-current] .site-header__primary-nav__list__link {
	border-bottom-color: var(--color-orange);
	color: var(--color-brown);
}

@media (prefers-contrast: more) {
	.site-header__primary-nav__list__link:hover,
	.site-header__primary-nav__list__item[data-is-current] .site-header__primary-nav__list__link {
		border-bottom-color: var(--color-brown);
	}
}

.site-header__primary-nav__list__link .icon-search {
	display: block;
	margin-inline-start: var(--space-8--px);
	width: var(--space-24--rem);
	height: var(--space-24--rem);
}
/* #endregion Primary Nav */

/* #region Secondary Nav */
.site-header__secondary-nav__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	padding-block: var(--space-24--rem);
}

.site-header__secondary-nav__list__item:not(:last-child) {
	margin-inline-end: var(--space-24--px);
}

.site-header__secondary-nav__list__link {
	display: block;
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.site-header__secondary-nav__list__link:hover,
.site-header__secondary-nav__list__item[data-is-current] .site-header__secondary-nav__list__link {
	text-decoration: underline;
}

.site-header__secondary-nav__list__link:hover {
	text-decoration-color: var(--color-orange);
}

@media (prefers-contrast: more) {
	.site-header__secondary-nav__list__link:hover {
		text-decoration-color: var(--color-brown);
		text-decoration-thickness: 2px;
	}
}
/* #endregion Secondary Nav */
/* #endregion Navigations */

/* #region Hamburger */
.site-header__hamburger {
	-mrh-resets: button;
	background-color: var(--color-orange-35);
	border-radius: var(--border-radius-5);
	display: flex;
	flex-direction: column;
	margin-top: var(--space-16--rem);
	padding: 1.375rem 19px;
	justify-content: space-between;
	align-items: center;
	transition: background-color 176ms ease-in-out;
	width: 3.5rem;
	height: 3.5rem;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-header__hamburger {
		display: none;
	}
}

.site-header[data-has-hero] .site-header__hamburger {
	background-color: var(--color-white);
}

body.is-showing-modal-dialog .site-header__hamburger,
.site-header__hamburger:hover,
.site-header[data-has-hero] .site-header__hamburger:hover {
	background-color: var(--color-orange);
}

.site-header__hamburger__line {
	background-color: var(--color-black);
	display: block;
	opacity: 1;
	transition:
		opacity 176ms ease-in-out,
		transform 176ms ease-in-out;
	transition-delay: 96ms;
	width: 1.125rem;
	height: 0.125rem;
}

body.is-showing-modal-dialog .site-header__hamburger__line:nth-child(2) {
	opacity: 0;
}

body.is-showing-modal-dialog .site-header__hamburger__line:first-child {
	transform: rotate(45deg) translate(0.25rem, 0.25rem);
	transform-origin: center;
}

body.is-showing-modal-dialog .site-header__hamburger__line:last-child {
	transform: rotate(-45deg) translate(0.1875em, -0.1875rem);
	transform-origin: center;
}
/* #endregion Hamburger */
