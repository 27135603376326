.row {
	margin-block: var(--space-40--rem);
	position: relative;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.row {
		margin-block: var(--space-56--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.row {
		margin-block: var(--space-80--rem);
	}
}
