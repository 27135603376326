.wrapper {
	box-sizing: border-box;
	width: 100%;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.wrapper {
		padding-inline: var(--space-15--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper {
		padding-inline: var(--space-18--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.wrapper {
		padding-inline: var(--space-20--px);
	}
}

/* #region Narrow */
.wrapper[data-size="narrow"] {
	padding-inline: var(--space-20--px);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.wrapper[data-size="narrow"] {
		padding-inline: var(--space-30--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper[data-size="narrow"] {
		margin: 0 auto;
		padding-inline: 0;
		max-width: 39.25rem;
	}
}
/* #endregion Narrow */

/* #region Medium */
.wrapper[data-size="medium"] {
	padding-inline: var(--space-20--px);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.wrapper[data-size="medium"] {
		padding-inline: var(--space-30--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper[data-size="medium"] {
		margin: 0 auto;
		padding-inline: 0;
		max-width: 43.5rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.wrapper[data-size="medium"] {
		max-width: 58rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.wrapper[data-size="medium"] {
		max-width: 61.875rem;
	}
}
/* #endregion Medium */

/* #region Wide */
.wrapper[data-size="wide"] {
	padding-inline: var(--space-20--px);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.wrapper[data-size="wide"] {
		padding-inline: var(--space-30--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper[data-size="wide"] {
		margin: 0 auto;
		padding-inline: 0;
		max-width: 43.5rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.wrapper[data-size="wide"] {
		max-width: 58rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.wrapper[data-size="wide"] {
		max-width: 69.25rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.wrapper[data-size="wide"] {
		max-width: 80.5rem;
	}
}
/* #endregion Wide */
