.breadcrumb {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.breadcrumb__item {
	display: inline;
}

.breadcrumb__item:not(:last-child)::after {
	background-image: url("../../../images/arrow.svg");
	background-position: center;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	margin-inline-end: var(--space-4--px);
	vertical-align: middle;
	width: 1.5rem;
	height: 1.5rem;
}

.breadcrumb__item [data-is-last-item] {
	font-weight: 400;
}

.breadcrumb__item__link {
	display: inline-block;
	margin-inline-end: var(--space-4--px);
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.breadcrumb__item__link:is(:hover, :active) {
	text-decoration-color: var(--color-black);
}
