:root {
	--more-contrast-outline-color: var(--color-brown);
}

@media (prefers-contrast: more) {
	[data-outline-able] {
		outline: 1px solid var(--more-contrast-outline-color);
	}

	[data-outline-able~="blue"] {
		--more-contrast-outline-color: var(--color-blue-dark);
	}

	[data-outline-able~="2"] {
		outline: 1px solid var(--more-contrast-outline-color);
		outline: 1px solid lab(from var(--more-contrast-outline-color) l a b / 0.75);
	}

	[data-outline-able~="3"] {
		outline: 1px solid var(--more-contrast-outline-color);
		outline: 1px solid lab(from var(--more-contrast-outline-color) l a b / 0.6);
	}

	[data-outline-able~="4"] {
		outline: 1px solid var(--more-contrast-outline-color);
		outline: 1px solid lab(from var(--more-contrast-outline-color) l a b / 0.45);
	}
}
