.video-embed {
	aspect-ratio: 16 / 9;
}

.video-embed__iframe {
	border-radius: var(--border-radius-10);
	display: block;
	width: 100%;
	height: 100%;
}
