.page-intro-home {
	display: grid;
	grid-row-gap: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.page-intro-home {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.page-intro-home {
		grid-row-gap: var(--space-56--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.page-intro-home {
		grid-column-gap: var(--space-32--px);
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
}

.page-intro-home__header {
	display: grid;
	grid-row-gap: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.page-intro-home__header {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.page-intro-home__header {
		grid-row-gap: var(--space-56--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.page-intro-home__header {
		align-self: center;
	}
}

.page-intro-home__header__title {
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--space-24--rem);
	column-gap: var(--space-24--px);
	margin-block-end: 0;
	align-items: center;
	text-wrap: balance;
}

.page-intro-home__header__text {
	color: var(--color-brown);
	margin: 0;
	text-wrap: balance;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.page-intro-home__header__text {
		max-width: 24.5rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.page-intro-home__header__text {
		max-width: 30.125rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.page-intro-home__header__text {
		max-width: 35.75rem;
	}
}
