.l-error-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	min-height: 100svh;
}

.l-error {
	background-color: var(--color-orange-10);
	box-sizing: border-box;
	margin-inline: var(--space-20--px);
	padding-inline: var(--space-24--px);
	padding-block-end: var(--space-24--rem);
	text-align: center;
	max-width: 25rem;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-error {
		margin-inline: 0;
	}
}

.l-error__header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.l-error__header__logo {
	width: 5.9375rem;
	height: 8rem;
}

.l-error__header__logo svg {
	width: 100%;
	height: 100%;
}

.l-error__main {
	margin-block-start: var(--space-24--rem);
}

.l-error__title {
	margin-block-start: var(--space-24--rem);
}

.l-error__content {
	margin: 0;
}
