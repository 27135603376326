:root {
	/* #region Colors */
	--color-foreground: rgb(0 0 0);
	--color-background: rgb(255 255 255);
	--color-black: rgb(0 0 0);
	--color-grey-dark: rgb(87, 87, 89);
	--color-grey-light: rgb(204, 204, 204);
	--color-grey-cool: rgb(231, 228, 222);
	--color-white: rgb(255 255 255);
	--color-brown: rgb(124, 52, 19);
	--color-red: rgb(188, 82, 23);
	--color-orange: rgb(241, 136, 43);
	--color-orange-35: rgb(255, 214, 183);
	--color-orange-10: rgb(255, 243, 235);
	--color-yellow: rgb(246, 168, 17);
	--color-blue-dark: rgb(41, 103, 108);
	--color-blue-light: rgb(105, 184, 193);
	--color-blue-light-20: rgb(225, 241, 243);
	--color-warning: rgb(242 149 0); /* Orange - contrast 4.5 & 7 */
	--color-error: rgb(207 0 0); /* Red - contrast 4.5 & 7 */
	/* #endregion Colors */

	/* #region Gradients */
	--gradient-yellow-orange: linear-gradient(45deg, var(--color-yellow) 0%, var(--color-orange) 100%);
	--gradient-red-brown: linear-gradient(45deg, var(--color-red) 0%, var(--color-brown) 100%);
	/* #endregion Gradients */

	/* #region Typography */
	--font-sans: "GT Pressura", "GT Pressura Fallback", sans-serif;
	--font-body-sans: "Noto Sans", "Noto Sans Fallback", sans-serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-2--px: 2px;
	--space-2--rem: 0.125rem;
	--space-4--px: 4px;
	--space-4--rem: 0.25rem;
	--space-6--px: 6px;
	--space-6--rem: 0.375rem;
	--space-8--px: 8px;
	--space-8--rem: 0.5rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-16--px: 16px;
	--space-16--rem: 1rem;
	--space-18--px: 18px;
	--space-18--rem: 1.125rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-24--px: 24px;
	--space-24--rem: 1.5rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-32--px: 32px;
	--space-32--rem: 2rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-48--px: 48px;
	--space-48--rem: 3rem;
	--space-56--px: 56px;
	--space-56--rem: 3.5rem;
	--space-80--px: 80px;
	--space-80--rem: 5rem;
	--space-96--px: 96px;
	--space-96--rem: 6rem;
	/* #endregion Spacers */

	/* #region Borders */
	--border-radius-5: 5px;
	--border-radius-10: 10px;
	--border-radius-9999: 9999px;
	/* #endregion Borders */

	/* #region Gaps */
	--row-gap: var(--space-32--rem);
	--column-gap: var(--space-32--px);
	/* #endregion Gaps */
}

@media (prefers-contrast: more) {
	:root {
		--color-blue-dark: rgb(28, 75, 78); /* color-mix(in oklch, rgb(41, 103, 108), black 20%) */
		--color-blue-light-20: rgb(234, 245, 247); /* color-mix(in oklch, rgb(225, 241, 243), white 30%) */
		--color-brown: rgb(90, 36, 11); /* color-mix(in oklch, rgb(124, 52, 19), black 20%) */
		--color-orange-10: rgb(255, 247, 241); /* color-mix(in oklch, rgb(255, 243, 235), white 30%) */
		--color-orange-35: rgb(255, 227, 205); /* color-mix(in oklch, rgb(255, 214, 183), white 30%) */
		--color-red: rgb(163, 70, 18); /* color-mix(in oklch, rgb(188, 82, 23), black 10%) */
	}
}

@media (prefers-contrast: less) {
	:root {
		--color-foreground: rgb(44, 14, 3); /* color-mix(in oklch, rgb(255, 243, 235), white 70%) */
		--color-background: rgb(255, 251, 249); /* color-mix(in oklch, rgb(255, 243, 235), white 30%) */
		--color-black: rgb(44, 14, 3); /* color-mix(in oklch, rgb(255, 243, 235), white 70%) */
		--color-white: rgb(255, 251, 249); /* color-mix(in oklch, rgb(255, 243, 235), white 30%) */
	}
}
