[data-zigzag] {
	color: var(--color-orange);
	position: relative;
	width: 10.5rem;
	height: 1.5rem;
}

[data-zigzag~="article"] {
	width: 7.5rem;
}

[data-zigzag~="pullquote"] {
	width: 8.5rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	[data-zigzag~="doormat"] {
		width: 8.5rem;
	}
}

[data-zigzag~="red"] {
	color: var(--color-red);
}

[data-zigzag]::before,
[data-zigzag]::after {
	background-position: top left;
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.8125rem;
}

[data-zigzag]::before {
	background-image: linear-gradient(-0.125turn, var(--color-white) 0.5rem, transparent 0), linear-gradient(0.125turn, var(--color-white) 0.5rem, transparent 0), linear-gradient(-0.125turn, currentColor 0.6875rem, transparent 0), linear-gradient(0.125turn, currentColor 0.6875rem, transparent 0);
	background-position-x: 0.25rem;
	background-size: 1rem 1rem;
}

[data-zigzag]::after {
	background-image: linear-gradient(-0.125turn, var(--color-white) 0.546875rem, transparent 0), linear-gradient(0.125turn, var(--color-white) 0.546875rem, transparent 0);
}
