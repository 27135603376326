.label {
	background-color: transparent;
	border: 0.125rem solid var(--color-orange);
	border-radius: var(--border-radius-5);
	color: var(--color-brown);
	display: inline-block;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-6--rem);
}

.label:not(:last-child) {
	margin-inline-end: var(--space-8--px);
}

.label[data-variant="date"] {
	border-color: var(--color-brown);
}
