.l-site {
}

.l-site__breadcrumb {
	margin-block-start: var(--space-56--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-site__breadcrumb {
		margin-block-start: var(--space-80--rem);
	}
}

/* #region Site header (has hero) + breadcrumb */
.site-header[data-has-hero] + .l-site__breadcrumb {
	margin-block-start: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.site-header[data-has-hero] + .l-site__breadcrumb {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header[data-has-hero] + .l-site__breadcrumb {
		margin-block-start: var(--space-56--rem);
	}
}
/* #endregion Site header (has hero) + breadcrumb */

.l-site__main {
	margin-block-start: var(--space-56--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-site__main {
		margin-block-start: var(--space-80--rem);
	}
}

/* #region Breadcrumb + main */
.l-site__breadcrumb ~ .l-site__main {
	margin-block-start: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.l-site__breadcrumb ~ .l-site__main {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-site__breadcrumb ~ .l-site__main {
		margin-block-start: var(--space-56--rem);
	}
}
/* #endregion Breadcrumb + main */
