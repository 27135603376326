.pagination {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.pagination__item {
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	color: var(--color-black);
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc((44px + 2.75rem + 2.75rem) / 3);
	height: calc((44px + 2.75rem + 2.75rem) / 3);
}

/* up-to-xsmall,max-width-567px */
@media (width < 35.5rem) {
	.pagination[data-has-prev-and-next] .pagination__item[data-hide-on-small-screen] {
		display: none;
	}
}

.pagination__item :any-link {
	border-radius: var(--border-radius-5);
	color: currentColor;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: background-color 176ms linear;
	width: 100%;
	height: 100%;
}

.pagination__item:hover :any-link {
	background-color: var(--color-orange);
	color: var(--color-black);
}

.pagination__item[data-is-current] {
	background-color: var(--color-brown);
	color: var(--color-white);
}

.pagination__item[data-direction="previous"] :any-link,
.pagination__item[data-direction="next"] :any-link {
	background-image: url("../../../images/arrow.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1.5rem 1.5rem;
}

.pagination__item[data-direction="previous"] :any-link {
	transform: scale(-1);
}
