.page-intro {
	display: grid;
	grid-row-gap: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.page-intro {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.page-intro {
		grid-row-gap: var(--space-56--rem);
	}
}

.page-intro__title {
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--space-24--rem);
	column-gap: var(--space-24--px);
	margin-block-end: 0;
	align-items: center;
	text-wrap: balance;
}

.page-intro__text {
	color: var(--color-brown);
	margin: 0;
	text-wrap: balance;
	max-width: 39.25rem;
}

.page-intro__labels {
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--space-8--rem);
	column-gap: var(--space-8--px);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* #region Search */
.page-intro__search-form {
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.page-intro__search-form {
		display: flex;
		justify-content: space-between;
	}
}

.page-intro__search-form__input {
	width: 100%;
}

.page-intro__search-form__button {
	-mrh-resets: button;
	background-color: var(--color-brown);
	border-radius: var(--border-radius-5);
	color: var(--color-white);
	display: inline-flex;
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.5; /* 150% */
	margin-block-start: var(--space-24--rem);
	padding-inline: var(--space-24--px);
	padding-block: var(--space-16--rem);
	align-items: center;
	transition: background-color 176ms ease-in-out;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.page-intro__search-form__button {
		margin-inline-start: var(--space-24--px);
		margin-block-start: 0;
	}
}

.page-intro__search-form__button:hover {
	background-color: var(--color-black);
}

.page-intro__search-form__button .icon {
	margin-inline-start: var(--space-8--px);
	width: 1.6875rem;
	height: 1.6875rem;
}
/* #endregion Search */
