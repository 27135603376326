.list-header {
	margin-block-end: var(--space-32--rem);
	max-width: 39.25rem;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.list-header {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.list-header {
		margin-block-end: var(--space-56--rem);
	}
}
