.list-teasers-b {
}

.list-teasers-b__items {
	display: grid;
	grid-row-gap: var(--row-gap);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.list-teasers-b__items {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.list-teasers-b__sub-title,
.list-teasers-b__link {
	margin-block-start: var(--space-32--rem);
	margin-block-end: 0;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.list-teasers-b__sub-title,
	.list-teasers-b__link {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.list-teasers-b__sub-title,
	.list-teasers-b__link {
		margin-block-start: var(--space-56--rem);
	}
}
