* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	line-height: 1.5; /* 150% */
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--color-brown);
	font-family: var(--font-sans);
	text-wrap: balance;
}

h1 {
	margin-block-start: var(--space-56--rem);
	margin-block-end: var(--space-56--rem);
}

h2,
h3,
h4,
h5,
h6 {
	margin-block-start: var(--space-56--rem);
	margin-block-end: var(--space-24--rem);
}

p {
	margin-block: var(--space-24--rem);
}

:is(h1, h2, h3, h4, h5, h6):first-child {
	margin-block-start: 0;
}

:is(h1, h2, h3, h4, h5, h6):last-child {
	margin-block-end: 0;
}

:is(h1, h2, h3, h4, h5, h6):only-child {
	margin-block-start: 0;
	margin-block-end: 0;
}

blockquote > cite,
blockquote > p > cite {
	font-style: normal;
	font-weight: 600;
}

.type-a,
h1 {
	font-family: var(--font-sans);
	font-size: 2.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.25; /* 125% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-a,
	h1 {
		font-size: 3.75rem;
		line-height: 1.2; /* 120% */
	}
}

.type-b,
h2 {
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.3; /* 130% */
}

.type-c,
h3 {
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.25; /* 125% */
}

.type-d,
h4,
h5,
h6 {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.3; /* 130% */
}

.type-e {
	font-family: var(--font-sans);
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.25; /* 125% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-e {
		font-size: 2.5rem;
	}
}

.type-f,
blockquote {
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */
}

.type-g {
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5; /* 150% */
}

.type-h,
blockquote > cite,
blockquote > p > cite {
	font-family: var(--font-body-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5; /* 150% */
}

.type-i {
	font-family: var(--font-body-sans);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5; /* 150% */
	text-transform: uppercase;
}

i,
em {
	font-style: italic;
}

blockquote:not([class]) {
	border-left: 3px solid var(--color-orange);
	margin-inline: 0;
	margin-block: var(--space-56--rem);
	padding-inline: var(--space-24--px);
	padding-block-end: var(--space-1--rem);
	position: relative;
}

blockquote:not([class]) > p,
blockquote:not([class]) > cite,
blockquote:not([class]) > p > cite {
	margin: 0;
}

blockquote:not([class]) > p:not(:last-child) {
	margin-block-end: var(--space-16--rem);
}
