.teaser-d {
	background-color: var(--color-orange-10);
	border-radius: var(--border-radius-10);
	display: flex;
	padding-inline: var(--space-24--px);
	padding-block: var(--space-24--rem);
	align-items: center;
}

.teaser-d__thumbnail-container {
	border-radius: var(--border-radius-9999);
	flex-shrink: 0;
	margin-inline-end: var(--space-24--px);
	overflow: hidden;
	width: 3.375rem;
	height: 3.375rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-d__thumbnail-container {
		width: 5rem;
		height: 5rem;
	}
}

.teaser-d__thumbnail {
	width: 100%;
}

.teaser-d__thumbnail__placeholder {
	background-color: var(--color-yellow);
	position: relative;
	width: 100%;
	height: 100%;
}

.teaser-d__thumbnail__placeholder::before {
	background-image: url("../../../images/person.svg");
	background-repeat: no-repeat;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1rem;
	height: 1rem;
}

.teaser-d__content {
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.teaser-d__title {
	color: var(--color-black);
	font-weight: 700;
	margin-block: 0;
}

.teaser-d__excerpt {
	margin: 0;
}

.teaser-d__link {
	font-weight: 600;
}

.teaser-d__link:is(:hover, :active) {
	color: var(--color-blue-dark);
}
