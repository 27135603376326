.contact-navigation {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.contact-navigation__item {
	display: flex;
}

.contact-navigation__item:not(:last-child) {
	margin-block-end: var(--space-8--rem);
}

.contact-navigation__item[data-icon]::before {
	background-color: var(--color-brown);
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-8--px);
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: 1.5rem 1.5rem;
	width: 1.6875rem;
	height: 1.6875rem;
}

.contact-navigation__item[data-icon="location"]::before {
	mask-image: url("../../../images/location-marker.svg");
}

.contact-navigation__item[data-icon="telephone"]::before {
	mask-image: url("../../../images/telephone.svg");
}

.contact-navigation__item[data-icon="mail"]::before {
	mask-image: url("../../../images/mail.svg");
}

.contact-navigation__item[data-icon="facebook"]::before {
	mask-image: url("../../../images/facebook.svg");
}

.contact-navigation__item[data-icon="instagram"]::before {
	mask-image: url("../../../images/instagram.svg");
}

.contact-navigation__item :any-link {
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.contact-navigation__item :any-link:is(:hover, :focus) {
	color: var(--color-brown);
	text-decoration-color: var(--color-orange);
}
