.cnw_w .cnw_searchresult .cnw_searchresult__searchwords .cnw_badge {
	background-color: var(--color-blue-dark);
	border-radius: var(--border-radius-5);
	display: inline-flex;
	margin: 0;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-8--rem);
	text-decoration: none;
	transition: background-color 176ms ease-in-out;

	/* type-h */
	font-family: var(--font-body-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
}

.cnw_searchresult .cnw_searchresult__searchwords .cnw_badge a {
	background-color: var(--color-white);
	color: transparent;
	margin-inline-start: var(--space-8--px);
	mask-image: url("../../../images/close.svg");
	mask-repeat: no-repeat;
	mask-size: contain;
	width: 1.3125rem;
	height: 1.3125rem;
}

.cnw_searchresult .cnw_searchresult__searchwords .cnw_badge:hover {
	background-color: var(--color-black);
}

@supports (selector(:has(foo))) {
	.cnw_searchresult .cnw_searchresult__searchwords .cnw_badge:hover {
		background-color: var(--color-blue-dark);
	}

	.cnw_searchresult .cnw_searchresult__searchwords .cnw_badge:has(a:hover) {
		background-color: var(--color-black);
	}
}
