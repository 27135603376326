.image-50-50 {
	display: grid;
	grid-row-gap: var(--space-32--px);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-50-50 {
		grid-column-gap: var(--space-32--rem);
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-50-50[data-only-image-b] .media {
		grid-column: 2;
	}
}
