.pullquote {
	margin: 0;
	text-align: center;
}

.pullquote__quote {
	color: var(--color-red);
	margin-block-start: 0;
	margin-block-end: var(--space-24--rem);
	text-wrap: pretty;
}

.pullquote__credit {
	background-color: var(--color-red);
	color: var(--color-white);
	padding-inline: var(--space-8--px);
	padding-block: var(--space-4--rem);
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}

.pullquote__zigzag {
	margin-inline: auto;
	margin-block-start: var(--space-24--rem);
}
