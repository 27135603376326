.button {
	-mrh-resets: button;
	background-color: var(--color-orange-10);
	border-radius: var(--border-radius-5);
	display: inline-flex;
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.5; /* 150% */
	padding-inline: var(--space-24--px);
	padding-block: var(--space-16--rem);
	align-items: flex-start;
	text-align: left;
	transition: background-color 176ms ease-in-out;
}

.button:hover {
	background-color: var(--color-orange);
}

.button::after {
	background-color: var(--color-black);
	box-sizing: border-box;
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-start: var(--space-8--px);
	mask-image: url("../../images/arrow.svg");
	mask-repeat: no-repeat;
	mask-size: contain;
	width: 1.6875rem;
	height: 1.6875rem;
}

/* #region Variant BLUE */
.button[data-variant="blue"] {
	background-color: var(--color-blue-dark);
	color: var(--color-white);
}

.button[data-variant="blue"]:hover {
	background-color: var(--color-black);
}

.button[data-variant="blue"]::after {
	background-color: var(--color-white);
}
/* #endregion Variant BLUE */

/* #region Variant ICON */
.button[data-icon]::before {
	background-color: var(--color-red);
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-8--px);
	mask-repeat: no-repeat;
	mask-size: contain;
	transition: background-color 176ms ease-in-out;
	width: 1.6875rem;
	height: 1.6875rem;
}

.button[data-icon]:hover::before {
	background-color: var(--color-black);
}

/* ICONS - they should all have the same size */
.button[data-icon="location"]::before {
	mask-image: url("../../images/location.svg");
}

.button[data-icon="activity"]::before {
	mask-image: url("../../images/activity.svg");
}

.button[data-icon="mail"]::before {
	mask-image: url("../../images/mail.svg");
}

.button[data-icon="download"]::before {
	mask-image: url("../../images/download.svg");
}
/* #endregion Variant ICON */
