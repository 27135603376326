.uit-widget .cnw_row {
	margin: 0;
}

.uit-widget .cnw_col-4,
.uit-widget .cnw_col-8,
.uit-widget .cnw_col-12 {
	padding-inline: 0;
	max-width: 100%;
}

.uit-widget .cnw_w:first-child {
	margin-inline: auto;
	margin-block-end: var(--space-32--rem);
	max-width: 61.875rem;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.uit-widget .cnw_w:first-child {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.uit-widget .cnw_w:first-child {
		margin-block-end: var(--space-56--rem);
	}
}

.uit-widget .cnw_w:first-child .cnw_container-fluid {
	max-width: 39.25rem;
}

/* #region GRID */
.uit-widget .cnw_container-fluid > .cnw_row.cnw_row {
	display: grid;
	grid-row-gap: var(--row-gap);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.uit-widget .cnw_container-fluid > .cnw_row.cnw_row {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.uit-widget .cnw_container-fluid > .cnw_row.cnw_row {
		grid-row-gap: var(--space-56--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.uit-widget .cnw_container-fluid > .cnw_row.cnw_row {
		grid-column-gap: var(--column-gap);
		grid-template-columns: 18.625rem 1fr;
	}
}
/* #endregion GRID */

/* #region Column (left) */
.uit-widget .cnw_col-4 {
	grid-row: 2;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.uit-widget .cnw_col-4 {
		grid-row: 1;
		grid-column: 1;
	}
}

.uit-widget .cnw_col-4 .cnw_facets {
	display: none;
}

.uit-widget .cnw_col-4 .cnw_html p:first-child {
	margin-block-start: 0;
	margin-block-end: var(--space-8--rem);
}

.uit-widget .cnw_col-4 .cnw_html p:last-child {
	margin-block-start: var(--space-8--rem);
	margin-block-end: 0;
}

.uit-widget .cnw_col-4 .cnw_html p {
	margin-block: var(--space-8--rem);
}

/* #region Button */
.uit-widget .cnw_col-4 .cnw_html a {
	-mrh-resets: button;
	background-color: var(--color-blue-light-20);
	border-radius: var(--border-radius-5);
	display: inline-flex;
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.5; /* 150% */
	padding-inline: var(--space-24--px);
	padding-block: var(--space-16--rem);
	align-items: flex-start;
	text-align: left;
	transition:
		background-color 176ms ease-in-out,
		color 176ms ease-in-out;
}

.uit-widget .cnw_col-4 .cnw_html a:hover {
	background-color: var(--color-blue-dark);
	color: var(--color-white);
}

.uit-widget .cnw_col-4 .cnw_html a:hover::after {
	background-color: var(--color-white);
}

.uit-widget .cnw_col-4 .cnw_html a::after {
	background-color: var(--color-black);
	box-sizing: border-box;
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-start: var(--space-8--px);
	mask-image: url("../../../images/arrow.svg");
	mask-repeat: no-repeat;
	mask-size: contain;
	width: 1.6875rem;
	height: 1.6875rem;
}

/* With Activity ICON */
.uit-widget .cnw_col-4 .cnw_html a[id="activity"]::before {
	background-color: var(--color-blue-dark);
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-8--px);
	mask-image: url("../../../images/activity.svg");
	mask-repeat: no-repeat;
	mask-size: contain;
	transition: background-color 176ms ease-in-out;
	width: 1.6875rem;
	height: 1.6875rem;
}

.uit-widget .cnw_col-4 .cnw_html a[id="activity"]:hover::before {
	background-color: var(--color-white);
}
/* #endregion Button */
/* #endregion Column (left) */

/* #region Column (right) */
.uit-widget .cnw_col-8 .cnw_searchresult__counter {
	display: none;
}

.uit-widget .cnw_col-8 {
	grid-row: 1;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.uit-widget .cnw_col-8 {
		grid-column: 2;
	}
}

/* #region Column (right) */
.uit-widget .cnw_col-8 .cnw_searchresult__results {
	display: grid;
	grid-row-gap: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.uit-widget .cnw_col-8 .cnw_searchresult__results {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.uit-widget .cnw_col-8 .cnw_searchresult__results {
		grid-row-gap: var(--space-56--rem);
	}
}

.uit-widget .cnw_col-8 .cnw_searchresult nav {
	display: none;
}
/* #endregion Column (right) */
