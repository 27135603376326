.filter-label {
	background-color: var(--color-brown);
	border-radius: var(--border-radius-5);
	color: var(--color-white);
	display: inline-flex;
	font-weight: 600;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-8--rem);
	text-decoration: none;
	transition: background-color 176ms ease-in-out;
}

.filter-label::after {
	background-color: var(--color-white);
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-start: var(--space-8--px);
	mask-image: url("../../images/close.svg");
	mask-repeat: no-repeat;
	mask-size: contain;
	width: 1.3125rem;
	height: 1.3125rem;
}

.filter-label:hover {
	background-color: var(--color-black);
}
