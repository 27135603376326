.logo-grid {
}

h2.logo-grid__title {
	margin-block-end: var(--space-32--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	h2.logo-grid__title {
		margin-block-end: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	h2.logo-grid__title {
		margin-block-end: var(--space-56--rem);
	}
}

.logo-grid__list {
	display: grid;
	grid-row-gap: var(--space-32--rem);
	grid-column-gap: var(--space-32--px);
	grid-template-columns: repeat(2, minmax(auto, 1fr));
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.logo-grid__list {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.logo-grid__list {
		grid-template-columns: repeat(4, minmax(auto, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.logo-grid__list {
		grid-template-columns: repeat(5, minmax(auto, 1fr));
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.logo-grid__list {
		grid-template-columns: repeat(4, minmax(auto, 1fr));
	}
}

.logo-grid__list__item {
	background-color: var(--color-orange-10);
	border-radius: var(--border-radius-10);
	display: block;
	padding-inline: var(--space-24--rem);
	padding-block: var(--space-24--rem);
}

.logo-grid__list__item__image {
	transition: transform 384ms ease-in-out;
	width: 100%;
}

.logo-grid__list__item:any-link:hover .logo-grid__list__item__image {
	transform: scale(1.125);
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.logo-grid__list__item:any-link:hover .logo-grid__list__item__image {
		transform: scale(1.085);
	}
}
