.l-article {
}

/* #region Zigzag */
/* up-to-large,max-width-1439px */
@media (width < 90rem) {
	.l-article__zigzag-container {
		display: none;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-article__zigzag-container {
		position: absolute;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-article__zigzag-container[data-variant="top"] {
		left: calc((100vw - 80.5rem) / 2);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-article__zigzag-container[data-variant="bottom"] {
		margin-top: -6.25rem;
		right: calc((100vw - 80.5rem) / 2);
	}
}
/* #endregion Zigzag */
