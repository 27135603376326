.cnw_pagination {
	display: flex;
	flex-wrap: wrap;
}

.cnw_pagination .cnw_page-item,
.cnw_pagination .pager-ellipsis {
	border-radius: var(--border-radius-5);
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc((44px + 2.75rem + 2.75rem) / 3);
	height: calc((44px + 2.75rem + 2.75rem) / 3);
}

.cnw_pagination .cnw_page-item .cnw_page-link {
	border: none;
	border-radius: var(--border-radius-5);
	color: currentColor;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: background-color 176ms linear;
	width: 100%;
	height: 100%;
}

.cnw_w .cnw_searchresult .cnw_pagination .cnw_page-link {
	/* type-g */
	font-family: var(--font-body-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
}

.cnw_pagination .cnw_page-item .cnw_page-link:hover {
	background-color: var(--color-blue-light-20);
}

.cnw_pagination .cnw_page-item.active .cnw_page-link {
	background-color: var(--color-blue-dark);
	color: var(--color-white);
}

/* #region Next/Prev */
.cnw_pagination .cnw_page-item.cnw_browse-next .cnw_page-link,
.cnw_pagination .cnw_page-item.cnw_browse-previous .cnw_page-link {
	background-image: url("../../../../images/arrow.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1.5rem 1.5rem;
	color: transparent;
	text-indent: -999rem;
}

.cnw_pagination .cnw_page-item.cnw_browse-previous .cnw_page-link {
	transform: scale(-1);
}
/* #endregion Next/Prev */

/* #region First/Last */
.cnw_pagination .cnw_page-item.cnw_browse-first,
.cnw_pagination .cnw_page-item.cnw_browse-last {
	display: none;
}
/* #endregion First/Last */
