.doormat {
	background: var(--theme-gradient);
	display: grid;
	grid-row-gap: var(--space-32--rem);
	padding-block-start: calc(var(--space-80--rem) - var(--space-16--rem));
	padding-block-end: var(--space-80--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.doormat {
		grid-row-gap: var(--space-40--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.doormat {
		grid-row-gap: var(--space-56--rem);
		padding-block-start: calc(var(--space-96--rem) - var(--space-16--rem));
		padding-block-end: var(--space-96--rem);
	}
}

.doormat__block {
	background-color: var(--color-white);
	border-radius: var(--border-radius-10);
	display: grid;
	grid-row-gap: var(--row-gap);
	padding-inline: var(--space-24--px);
	padding-block: var(--space-24--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.doormat__block {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(2, minmax(0, 1fr));
		padding-inline: var(--space-56--px);
		padding-block: var(--space-56--rem);
	}
}

.doormat__block__title {
	color: var(--color-brown);
	margin-block-end: var(--space-16--rem);
	text-wrap: balance;
}

.doormat__block__text {
	margin-block-end: var(--space-24--rem);
}

.doormat__block__text p:first-child {
	margin-block-start: 0;
}

/* #region Contact */
.doormat__block__contact {
	border-top: 1px solid var(--color-grey-light);
	display: flex;
	padding-block-start: var(--space-24--rem);
	align-items: center;
}

.doormat__block__contact__thumbnail-container {
	border-radius: var(--border-radius-9999);
	flex-shrink: 0;
	margin-inline-end: var(--space-24--px);
	overflow: hidden;
	width: 5rem;
	height: 5rem;
}

.doormat__block__contact__thumbnail {
	width: 100%;
}

.doormat__block__contact__title {
	font-weight: 700;
	margin: 0;
}

.doormat__block__contact__phone_number {
	margin: 0;
}

.doormat__block__contact__link {
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.doormat__block__contact__link:is(:hover, :active) {
	color: var(--color-blue-dark);
}
/* #endregion Contact */

/* #region Links */
.doormat__block__links {
	display: flex;
	flex-direction: column;
	margin-block-start: var(--space-24--rem);
	align-items: flex-start;
}

.doormat__block__links :not(:last-child) {
	margin-block-end: var(--space-8--rem);
}
/* #endregion Links */
