.site-footer {
	color: var(--color-brown);
	position: relative;
}

.site-footer::before {
	background:
		linear-gradient(-45deg, var(--color-orange-10) 0.5rem, transparent 0) 0 0.5rem,
		linear-gradient(45deg, var(--color-orange-10) 0.5rem, transparent 0) 0 0.5rem;
	background-position: top left;
	background-size: 1rem 1rem;
	content: "";
	display: block;
	position: absolute;
	top: -1rem;
	right: 0;
	left: 0;
	height: 1rem;
}

.site-footer h3 {
	color: var(--color-brown);
}

.site-footer__top {
	background-color: var(--color-orange-10);
	padding-block: var(--space-40--rem);
	position: relative;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.site-footer__top {
		padding-block: var(--space-56--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-footer__top {
		padding-block: var(--space-80--rem);
	}
}

.site-footer__title {
	color: var(--color-brown);
	margin-block-end: var(--space-40--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__title {
		margin-block-end: var(--space-56--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__title {
		max-width: 39.25rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-footer__title {
		margin-block-end: var(--space-80--rem);
	}
}

.site-footer__navigation {
	display: grid;
	grid-row-gap: var(--row-gap);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__navigation {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.site-footer__navigation__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__navigation__list__item:not(:last-child) {
	margin-block-end: var(--space-8--rem);
}

.site-footer__navigation__list__item__link {
	text-decoration-color: transparent;
	transition: text-decoration-color 176ms ease-in-out;
}

.site-footer__navigation__list__item__link:is(:hover, :focus),
.site-footer__navigation__list__item[data-is-current] .site-footer__navigation__list__item__link {
	text-decoration-color: var(--color-orange);
}

.site-footer__bottom {
	background-color: var(--color-orange-35);
	padding-block: var(--space-24--rem);
	position: relative;
}

@media (prefers-contrast: more) {
	.site-footer__bottom {
		color: var(--color-black);
	}
}

.site-footer__bottom :any-link:hover {
	color: var(--color-black);
	text-decoration-color: var(--color-black);
}
